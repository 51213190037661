.template-wrap{
    background-color: pink;
    height: 100%;
    width: 100%;
}
.agendamento{
    background-color: #E7366A !important;
    border-radius: 3px !important;
    cursor: pointer !important;
}
.agendamento-bloqueado{
    background-color: #E5E5E5 !important;
    border-radius: 3px !important;
    color: #8F8F8F !important;
}
.agendamento-cancelado{
    background-color: #6461F3 !important;
    border-radius: 3px !important;
}
.agendamento-realizado{
    background-color: #33DAF8 !important;
    border-radius: 3px !important;
}
.e-cell-popup{
    display: none;
}
.e-dlg-container {
    display:none !important; 
}
.e-quick-popup-wrapper{
    display: none !important;
}
.retorno{
    background-color: #27b8d2 !important;
    cursor: pointer !important;
}
.e-schedule .e-agenda-view{
    background-color: #fff !important;
    cursor: pointer !important;
}
.e-schedule .e-agenda-view .e-appointment {
    border-left: 3px solid #E7366A !important;
    cursor: pointer !important;
}
.e-appointment{
    animation-name: example;
    animation-duration: 0.5s;

}
.e-content-wrap{
    min-height: 76vh;
}
.e-subject{
    text-transform: capitalize;
}

@keyframes example {
    0%   {
  
        transform: scale(0);
    }
    100% {

        transform: scale(1);
    }
  }