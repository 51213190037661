.MenuTopo-logo-login{   
    width: 6rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.MenuTopo-logo-login img{   
    width: 100%;
    height: auto;
}
.menuTopo-envolve-logo-botoes{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.MuiBadge-badge.MuiBadge-anchorOriginTopRightRectangle.MuiBadge-colorSecondary{
    background-color:  rgb(231, 54, 106);
}
a .MuiTypography-root.MuiListItemText-primary.MuiTypography-displayBlock{
    color: #ffff;
}
.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button a{
    text-decoration: none;
}

.MuiButtonBase-root.MuiListItem-root:hover .MuiSvgIcon-root,
.MuiButtonBase-root.MuiListItem-root:hover  a div span,
.MuiButtonBase-root.MuiListItem-root:hover  div span
{
    color: #fff !important;
}
.menuTopo-img-perfil span.MuiIconButton-label{
    width: 40px;
    height: 40px;
    border-radius: 8px;
}
.menuTopo-img-perfil img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #27b8d252;
    padding: 2px;
}
.menuTopo-menu-lateral{
    display: flex;
    justify-content: center;
    align-items: center;
}
.menuTopo-icon-hamburguer .MuiSvgIcon-root{
    color: #fff;
    width: 1.8rem;
    height: 1.8rem;
  }
.menuTopo-collapse{
    min-height: 0px;
    height: auto;
    transition-duration: 204ms;
    position: absolute;
    background: #27b8d2;
    width: 45%;
    box-shadow: 0px 8px 8px -8px darkgrey;
}