.container-envolve-planos{
    margin-top: 5rem;
    margin-left: 1rem;
    margin-right: 1rem;
}
.titulo-pag-planos{
    text-align: center;
    color: #8F8F8F;
}
.titulo-pag-planos h1{
   margin-bottom: 0;
   font-size: 1.7em;
}
.titulo-pag-planos p{
    font-size: 1.3m;
 }
 .card-planos{
    width: 25%;
    height: auto;
    min-height: 530px;
    background: white;
    /* padding: 2.5rem; */
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0px 0px 6px 0px #e2e2e2;
    margin: 10px;
    margin-bottom: 2rem;
 }
 .envolve-titulo-card-planos{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 1rem;
    border-bottom: 1px solid #c7c7c7;
    margin-bottom: .4rem;
}
.envolve-titulo-card-planos p, .envolve-titulo-card-planos h2{
    margin: 0;
}
.envolve-titulo-card-planos p{
    margin-bottom: .3rem;
    color: #8f8f8f;
}
.envolve-titulo-card-planos p span.preco-plano{
    font-size: 3.5em;
    font-weight: 700;
    color: #8f8f8f;
    margin-right: .3rem;
}
.envolve-titulo-card-planos p span.preco-moeda{    
    color: #8f8f8f;
    font-size: 1.7em;
    font-weight: 500;
    margin-right: .4rem;
}
.envolve-titulo-card-planos p span.preco-periodo{
    color: #8f8f8f;
    font-weight: 400;
    font-size: 1.7em;
}
.envolve-titulo-card-planos h2{
    color: #8f8f8f;
}
.btn-selecionar-plano{
    width: 87%;
    margin-top: .5rem !important;
}
.ul-list-descricao{
    list-style-type: disc;
}
.div-ul-list-descricao{
    width: 100%;
}
.div-ul-list-descricao ul li{
    margin-bottom: .7rem;
    color: #8f8f8f;
}
.envolve-todos-cards{
    display: flex;
    justify-content: center;
}
.envolve-periodo-card{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
}
.envolve-periodo-card-plan{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0px solid #27b8d2;
    border-radius: 30px;
    width: 15%;
    background: #FFF;
    box-shadow: 0px 0px 5px 0px #00000022;
}
.envolve-periodo-card-plan-active{
    background-color: #27b8d2;
    color: white;
    border-radius: 30px;
    width: 50%;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .9em;
    padding-right: 2rem;
    padding-left: 2rem;
}
.envolve-periodo-card-plan-desactive{
    color: #27b8d2;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .9em;
    cursor: pointer;
    padding-right: 2rem;
    padding-left: 2rem;
}
.envolve-periodo-card-plan-desactive p, .envolve-periodo-card-plan-active p{
  margin: .5rem;
}
.contratar-plano .card-planos {
    width: 100%;
    height: auto;
    min-height: 580px;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 0px 6px 0px #e2e2e2;
    margin: 2rem 0;
    padding: 0rem;
    border-radius: 0px 0px 10px 10px;
}
.contratar-plano {
    margin-top: 4rem;
    height: 89vh;
}
.contratar-plano .MuiGrid-root.MuiGrid-container{
    height: 89vh;
}
.contratar-plano .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-9{
    height: 89vh;
}
.contratar-plano .MuiPaper-root.MuiPaper-rounded{
    height: 100%;
    box-shadow: 0px 0px 0px 0px gray;
}
.contratar-plano .envolve-todos-cards{
    height: 87vh;
}
.btn-selecionar-plano.contratar-plano-btn{
    background-color: #aaaaaa; 
    width: 100%;
    cursor: unset;
}
.btn-selecionar-plano.contratar-plano-btn:hover{
    background-color: #aaaaaa; 
}
.contratar-plano .envolve-titulo-card-planos h2{
    color: #27b8d2;
}
.contratar-plano .envolve-titulo-card-planos p{
    color: #27b8d2;
}
.contratar-plano .envolve-titulo-card-planos p span{
    color: #27b8d2;
}
.contratar-plano .envolve-titulo-card-planos{
    border-bottom: 1px solid #27b8d2;
}
.card-header{
    width: 100%;
    background-color: #e7366a;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 1.1em;
    margin-top: 0rem;
    border-radius: 10px 10px 0px 0px;
    box-shadow: 0px 0px 6px 0px #e2e2e2;
}
.card-body {
    padding: 2.5rem;
}
.contratar-plano .envolve-periodo-card-plan {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #27b8d2;
    border-radius: 30px;
    width: 20%;
}
.contratar-plano .envolve-periodo-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
}
.dados-cartao-contratar-plano{
    margin-right: 2rem;
    margin-left: 2rem;
}
.dados-cartao-contratar-plano h1, .dados-cartao-contratar-plano h2 {
    color: #8f8f8f;
}
.p-contratar-plano{
    margin-top: 3rem;
}
.contratar-plano .envolve-periodo-card{
    margin: 0;
    margin-bottom: 1.5rem;
}
.rccs__card{
    margin-bottom: 2rem !important;
}
.contratar-plano-card{
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
}
.rccs{
    margin: 0 !important;
}
.contratar-plano .MuiFormControl-root{
    width: 95%;
}
.widt-card-plan-escolhido{
    width: 65%;
}
.btn-footer-contratar-plan button{
    margin: 1rem;
}
.btn-footer-contratar-plan{
    display: flex;
    width: 92%;
    align-items: center;
    justify-content: flex-end;
}
.usar-cupon-btn{
    padding-right: 0;
}
.usar-cupon-btn button{
    height: 55px;
    width: 17ch;
    border-radius: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.contratarPlano{
    padding: 1rem;
    margin-left: 4rem;
    margin-right: 4rem;
    padding-bottom: 3rem;
}
.div-btn-pagar{
    display: flex;
    justify-content: flex-end;
    width: 90%;
}
.alert-pagamento{
    margin-bottom: 1rem;
    width: 90%;
}

@media (max-width: 1279px){
    .black-friday-card-container{
        width: 350px;
    }
    .envolve-todos-cards{
        margin-top: 40px;
        width: 100%;
    }
    .card-body{
        padding: 10px .5rem;
    }
   
}
@media (max-width: 600px){
  
    .container-black-friday-responsive{
        margin: 0 !important;
    }
    .contratarPlano{
        margin: 0;
    }
}