.applepay-notification {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.applepay-notification .applepay-notification__content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 700px;
}

.applepay-notification .applepay-notification__content h2 {
  color: #8f8f8f;
  text-align: center;
}

.applepay-notification .applepay-notification__content button {
  margin-top: 17px;
  background-color: #27b8d2;
  color: #ffffff;
}

@media (max-width: 740px) {
  .applepay-notification .applepay-notification__content {
    width: 80%;
  }
}