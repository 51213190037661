.ListagemModelo-container .MuiGrid-root.MuiGrid-container{
    height: 90vh;
    /* padding-top: 6rem; */
}
.ListgemModelo-prontuarios{
    /* padding-top: 2rem!important; */
    margin-left: 6rem;
    margin-right: 6rem;
    background-color: white;
    /* padding: 2rem; */
    min-height: 87vh;
    margin-top: 2rem;
}
.ListgemModelo-prontuarios .MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit.Mui-selected{
    color: #27b8d2;
}
.ListgemModelo-prontuarios h1{
    color: #8F8F8F;
    font-family: 'Roboto',Arial, Helvetica, sans-serif;
    font-weight: 500;
    margin-bottom: 0.5;
}
.CardModelos-envolve{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dedede;
}
.CardModelos-envolve-text-icon{
    display: flex;
    align-items: center;
    min-width: 200px;
}

.CardModelos-envolve-text-icon-2{
    display: flex;
    align-items: center;
    min-width: 200px;
    justify-content: flex-end;
}


.CardModelos-icon-pront{
    margin-right: .5rem;
    color: #27b8d2;
}
.CardModelos-envolve-text-icon p{
    color: #8F8F8F;
}
.CardModelos-envolve .MuiIconButton-root{
    color: #8F8F8F;
}
.ListagemModelo-prontuario-name-button{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
}
.padding-lateral{
    padding-right: 24px;
    padding-left: 24px;
}
.retirar-padding-lateral .MuiBox-root{
    padding-right: 0px;
    padding-left: 0px;
}
.Color-btn-addModelo{
    background-color: #27b8d2 !important;
    color: white !important;
}
.div-load-lista-modelos{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10vh;
}
.div-load-lista-modelos svg{
    color: #27b8d2 !important;
}
.app-bar-modelos-pront .MuiAppBar-colorPrimary{
    background-color: white;
}