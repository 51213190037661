.container-tabela {
    margin-top: 40px;
    padding-bottom: 10px;
    min-height: 80vh;
    padding-bottom: 3rem;
    overflow-y: auto;
}
.MuiGrid-root.table-div-scroll{
    height: 100vh;
    overflow: auto;
}