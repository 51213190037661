.Header-Informações-Positions{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Header-Informações{
  margin-left: 40px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.Header-Informações p {
  margin-bottom: 5px;
  color: #8F8F8F;
  font-size: 20px;
  font-weight: 700;
}

.Header-Informações span {

  margin: 0px;
  color: #8F8F8F;
}

.Header-Btn-Imprimir-Position{
  margin-right: 40px;
  margin-top: 20px;
}

.Header-Btn-Imprimir{
  margin-left: 40px;
  margin-top: 20px;
  border: none;
  background-color: #219FB6;
  width: 200px;
  height: 50px;
  border-radius: 2px;
  color: #FFF;
  font-size: 16px;
  box-shadow: 2px 2px 5px 0px #0000002b;
  cursor: pointer;
  font-weight: 600;
  
}

.Header-Btn-Imprimir:hover{

  background-color: #138599;

}

@media print {
  .Header-Btn-Imprimir{
    visibility: hidden;
  }
}

.ListgemModelo-prontuarios-2{
  /* padding-top: 4rem; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-left: 2rem;
  margin-right: 2rem;
  background-color: white;
  padding: 0.9rem;
  min-height: 50vh;
  /* margin-top: 2rem; */
}

.ListgemModelo-prontuarios-3{
  /* padding-top: 4rem; */
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: 2rem;
  margin-right: 2rem;
  background-color: white;
  padding: 0.9rem;
  min-height: 50vh;
  /* margin-top: 2rem; */
}

.ListgemModelo-prontuarios-4{
  /* padding-top: 4rem; */
  /* display: flex; */
  flex-wrap: wrap;
  justify-content: space-around;
  margin-left: 2rem;
  margin-right: 2rem;
  background-color: white;
  padding: 0.9rem;
  min-height: 50vh;
  /* margin-top: 2rem; */
}