.options{
    display: flex;
    margin: 10px;
}
.options-inputs{
    width: 50%;
}
.options-buttons{
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}
.option-title{
    width: 100%;
}
.options-items{
    border: 1px solid #c4c4c4;;
    width: 100%;
    margin-left: 10px;
    border-radius: 3px;
    padding-top: 10px;
    padding-left: 0px;
    padding-bottom: 10px;
    height:  109px;
    overflow-y: scroll;
    cursor: pointer;
}
.options-items ul{
    list-style: none;
    color:#8F8F8F;
    padding-left: 0px;
}
.options-items ul li{
    margin-bottom: 3px;
    padding-left: 15px;
}
.options-buttons button{
    margin-right: 5px;
}   
.options-buttons-incluir{
    background-color: #27B8D2 !important;
    color:#fff !important;
}
.options-buttons-editar{
    background-color: #fff !important;
    color: #8F8F8F !important
}
.options-buttons-excluir{
    background-color: #E7366A !important;
    color: #fff !important;
}

.option-selected{
    background: #8f8f8f24;
}
.escala .MuiInputBase-formControl{
    margin-right: 15px ;
}
.image-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.img-input{
    width: 100%;
    margin-right: 15px !important;
}

input[type='file'] {
    display: none
}

.inputdm{
    margin:'15px';
    border:'1px solid red';
    height: '400px';
    
}
.upload{
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    background-color: #27B8D2 !important;
    color: #fff !important;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    background-color: #e0e0e0;
    cursor: pointer;
    display: flex;
    justify-content: center;
}

.upload:hover{
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    background-color: #d5d5d5;
}

.eixos{
    display: flex;
    justify-content: space-between;
}
.content-eixo{
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn-eixo{  
    margin-left: 5px !important;
}
.input-escala{
    background-color: #27B8D2;
}
.titleEscale{
    display: flex;
    justify-content: space-between;
}
.h3-title-card-input{
    white-space: nowrap;
    margin-right: 15px;
}
.img-class{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
    
}
.img-class img{
    width: auto;
    max-height: 400px;
    max-width: 100%;/* max-width: 520px; */
    width: auto;
    padding: 10px;
}
.tabela-lista{
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 5px;
}
.tabela-lista li{
    margin-top: 5px;
}
.box-table{
    border: 1px solid #c4c4c4;
    width: 85%;
    margin-top: 10px;
    border-radius: 3px;
    padding-top: 0px;
    padding-left: 0px;
    padding-bottom: 10px;
    height: 109px;
    overflow-y: scroll;
    cursor: pointer;
}