.erroDados{
    text-align: center;
    width: 100%;

}
.div-load{
    display: flex;
    justify-content: center;
    width: 100%;
}
.div-load{
    margin-top: 30px;
}
