.sub-header {
    background-color: #FFFFFF;
    border-bottom: 1px solid #DDDDDD;
    min-height: 80px;
    padding: 10px 20px;
    position: fixed;
    top: 64px;
    width: 100%;
    text-align: right;
}
