.container-agenda {
    margin: 8rem 4rem;
    /* padding-top: 1.5rem; */
}
.makeStyles-paper-14 {
    height: 100% !important;
    min-height: 100vh;
    box-shadow: 0px 0x 0px 0px gray;
    border-radius: 0px;
}

.Modal-form-add-agendamento{
    display: flex;
    justify-content: center;
    align-items: center;
}
.Modal-agendamento-alert{
    margin-bottom: 2rem;
}
.Modal-form-add-agendamento-btn-add-proc{
    margin-top: 2rem !important;
    padding: 0 !important;
    text-align: left;
}
.Modal-form-add-agendamento-div-proc{
    display: flex;
    flex-direction: column;
    color: gray; 
    width: 100% !important;
}
.Modal-form-add-agendamento-div-proc p{
    margin: 0;
    margin-left: .3rem;
    margin-top: 0rem;
}
.Modal-form-add-agendamento-div-proc .MuiFormControl-root{
    margin-top: 0;
}
.load-detalhe-agendamento .loadCircular svg{
    color: #27b8d2 !important;
}
.load-detalhe-agendamento .loadCircular{
    margin-left: 1rem;
}
.load-detalhe-agendamento{
    display: flex;
    align-items: center;
    justify-content: center;
}