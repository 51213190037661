.google-login .google-btn {
    background-color: #fdfdfd !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 10px 30px !important;
    box-shadow: 0px 0px 6px 0px rgb(221, 221, 221)!important;
    height: 50px;
    border-radius: 4px !important;
    width: 100%;
    opacity: 1 !important;
    border: 1px solid rgb(228, 228, 228);
}

.google-login .google-btn:hover {
    background-color: #EFEFEF !important;
    cursor: pointer !important;
}

.google-login .google-btn:focus {
    outline: none !important;
}

.google-login .google-btn span {
    color: #808080 !important;
    padding: 0 !important;
}

.google-btn>div {
    margin-right: 15px !important;
    background: rgb(255 255 255 / 0%) !important;
    padding: 0px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.google-icon {
    color: #fff !important;
    font-size: 1.1em !important;
    margin-right: 15px !important;
}
