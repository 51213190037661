.signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.signup-wrapper {
    width: 100%;
    height: 100vh;
    overflow: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #ffffff;
    justify-content: center;
}

.signup-form {
    padding: 0rem 1rem;
    width: 60%;
}

.envolve-logo-back {
    width: 55%;
    height: 100vh;
}

.header-logo {
    margin-top: 2rem;
    width: 10rem;
    height: auto;
}

.signup-title {
    font-size: 1.2em;
    color: rgba(0, 0, 0, 0.87);;
    font-weight: 400;
    font-family: 'Roboto',Arial,sans-serif;
    margin-top: 2rem;
    margin-bottom: 2rem !important;
}

.envolve-form-cadastro .MuiPaper-root.MuiStepper-root.MuiStepper-horizontal.MuiStepper-alternativeLabel {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;
    margin-top: 2rem;
}

.envolve-textField {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.envolve-textField .MuiFormControl-root.MuiTextField-root {
    margin-bottom: 1rem;
}

.MuiStep-root.MuiStep-horizontal.MuiStep-alternativeLabel {
    max-width: 28%;
}

.div-btn-cadastro {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0 !important;
}

.margin-left-icon-cadastro {
    margin-left: .5rem;
}

.div-envolve-input-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.input-uf {
    width: 30%;
    margin-left: 1rem !important;
}

.input-br {
    width: 70%;
}

.lateralStepCadastro {
    width: 100%;
    height: 100vh;
    background: white;
    overflow: auto;
}

.section-lateral-cadastro {
    padding: 1rem;
    width: 100%;
    margin-bottom: 1rem;
    border-radius: 6px;
    cursor: pointer;
}

.section-lateral-cadastro.active {
    background: white;
    box-shadow: 0px 0px 6px 0px #e4e4e4;
}

.section-lateral-cadastro p {
    margin: 0;
    color: #27b8d2;
    font-weight: 500;
}

.envolve-section-lateral-cadastro {
    width: 100%;
}

.NovoModelo-envolve-laterais {
    height: 100vh;
    background-color: white;
    overflow-y: auto;
    position: relative;
}

.lateralSecaoStep {
    padding: 2rem;
}

.lateralSecaoStep-FormPront {
    width: 100%;
    background-color: #f9f9f9;
    padding: 1rem;
    margin-top: 3rem;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.lateralSecaoStep-nomePront {
    width: 100%;
    margin-left: 1rem;
    color: #8f8f8f;
    font-size: 1.2em;
    font-weight: 500;
    margin-top: .5rem;
    margin-bottom: 1rem !important;
}

.form-cadastro-editar {
    background-color: white;
    padding: 2rem;
    margin: 5rem;
    border-radius: 6px;
    margin-bottom: 0;
    margin-top: 5rem;
}

.form-cadastro-editar form .divCadastrarEditar {
    display: flex;
    flex-direction: column;
}

.form-cadastro-editar p {
    margin: 0;
    margin-bottom: 1.5rem;
    color: #8f8f8f;
    font-size: 1.2em;
    font-weight: 500;
}

.form-cadastro-editar form .MuiOutlinedInput-root {
    margin-bottom: 1rem;
}

.div-btn-cadastrar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.btn-salvar-editar-cadastro {
    margin-left: 1rem !important;
}

.p-logo-empresa {
    margin: 0 !important; 
    margin-right: 1rem !important;
    font-weight: 400 !important;
    color: rgb(131, 131, 131) !important;
}

.div-logo-empresa {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
}

.div-envolve-telefones {
    display: flex;
    width: 100%;
}

.div-envolve-telefones-cellphone {
    width: 50%;
}

.div-envolve-telefones-phone {
    width: 50%;
    margin-right: 1rem !important;
}

@media (min-width: 1600px) {
    .MuiStep-root.MuiStep-horizontal.MuiStep-alternativeLabel {
        max-width: 30%;
    }
}
  
@media screen and (max-width: 600px) {
    .signup-form {
        width: 90%;
    }
}