.loadingPaciente{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
}
.loadingPaciente div{
    width: 70px !important;
    height: 70px !important;
}
