.Grade-Container-Graficos{

display: flex;
flex-wrap: wrap;
margin: 10px;
width: 40%;
/* justify-content: flex-end; */
flex-direction: column;
}


.Grade-Container-Graficos2{
  display: flex;
  flex-wrap: wrap;
  margin: 10px;
  width:750px;
  /* justify-content: flex-end; */
  flex-direction: column;
  }

/* usar o css acima para dimencionar o tamanho da img dos gráficos, se necessario criar os gráficos 3 e 4 */

.Grade-Container-Graficos-interpaciente{
  display: flex;
  flex-wrap: wrap;
  margin: 20px 5px;
  /* width: 100%; */
  justify-content: flex-start;
  flex-direction: column;
  height: 350px;
  }

.Grade-width{
  width: 50%;
}

.Grade-width-2{
  width: 80%;
}

.Container-Informações-Grafico{
  display: flex;
}

.Container-Informações{
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* margin: 5px; */
  padding: 0px 10px;
}

.Informações{
  /* padding: 5.5px 0px; */
  margin: 9px 0px;
}

.Title-Grafico2  p{
  width: 70%;
  color: #27B8D2;
  font-weight: 700;
  font-size:  18px !important;
  margin: 0px ;
  margin-top: 25px;
  display: contents;
  margin-bottom: 20px;
}

.Title-Grafico2{
  margin-top: 5px;
  margin-bottom: 5px;
}