.title-atendente {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #27b8d2;
}

.title-fechar{
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #27b8d2;
}

.tamanho-modal{
  /* width: 600px; */
  height: auto;
}

