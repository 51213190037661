form .mb-4{
    margin-bottom: 1.5rem;
}
div .titulo-form{
    color: #27b8d2;
    font-size: 1.1em;
    font-weight: 500;
    margin: 0;
}
.div-checkbox-margin{
    margin-left: 2rem;
    margin-bottom: 2rem;
}
