.container{
    margin: 2rem 4rem;
}
.header-name{
    color:#27B8D2;
    font-size: 1.5em;
    font-family: Roboto;
    text-transform: capitalize;
}
.inputs-headers{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
}
.buttonHeader{
    font-weight: 700;
    font-size: 14px;
    background: #27b8d2;
    box-shadow: 0px 8px 18px rgb(25 32 56 / 8%);
    border-radius: 4px;
    border: none;
    width: 160px;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-right: 15px;
    padding: 15px;
    text-align: left;
    cursor: pointer;
    color: #ffffff;
}

button[disabled]{
    opacity: 0.5;
}

.buttonHeader[disabled]{
    opacity: 0.5;
}

.buttonHeader span {
    margin-left: 5px;
}
.buttonHeader svg {
    /* margin-right: .5rem; */
}
.button-group-table{
    display: flex;
    margin-left: 1rem;
}

.div-group-btn-alert{
    display: flex;
    flex-direction: column;
}

.div-group-btn{
    display: flex;
}

.div-group-alert{
    display: flex;
    align-items: center;
    justify-content: center;
}

.div-group-alert p{
    margin: 5px;
    margin-bottom: -15px;
    color: red;
    font-weight: 400;
}

.mr-0{
    margin-right: 0 !important;
}

#Modal-title-agendamento{
    color: #27B8D2;
}
.title-modal-close{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.title-modal-close svg{
    cursor: pointer;
}
.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper {
    min-width: 675px;
}
.MuiGrid-root.MuiGrid-container.MuiGrid-justify-xs-space-around{
    align-items: flex-end;
    margin-top: 1rem;
    margin-bottom: .5rem;
}
.MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal{
    margin: 0;
}
.Modal-p-as-hora{
    color: #8F8F8F;
    margin: 0;
}
.MuiPickersBasePicker-pickerView {
    min-width: 675px !important;
}
.MuiAppBar-colorPrimary {
    box-shadow: 0px 0px 0px 0px !important;
}

.dialogteste > div > div{
    min-width: 800px;
}