.login-background-image {
    background-image: url('../../../Assets/Images/bg.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-card-form-login {
    width: 22rem;
    min-height: 32rem;
    max-height: 40rem;
    background: #F8F8F8cc;
    box-shadow: 3px 2px 8px 3px rgb(0 0 0 / 10%);
    border-radius: 6px;
    padding: 2rem;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin: auto;
}

div.Login-alert {
    margin-bottom: 1.5rem;
    width:100%;  
}

.loadCircular svg {
    color: white;
}

.Login-logo {
    margin-bottom: 1.1rem;
}

div .lost-password {
    text-transform: none;
    font-family: 'Roboto',Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.8em;
    text-align: center;
    width: 100%;
    line-height: 20px;
    color: #5A5A5A;
    margin-top: .7rem;
    margin-bottom: 0rem;
}

.trial {
    border-radius: 5px;
    margin-top: 1rem;
    width: 100%;
    text-align: center;
}

.trial p {
    font-family: 'Roboto';
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: .9em;
    line-height: 19px;
    color: #E83368;
    margin-top: 1rem;
}
.trial p .Login-link-teste-gratuito-clinic {
    text-transform: none;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    text-decoration-line: underline;
    color: #27b8d2;
}

.Login-div-btn-google{
    width: 100%;
    margin: .7rem;
}
.Login-ou {
    font-family: 'Roboto',Arial, Helvetica, sans-serif;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}

.Login-ou span {
    width: 100%;
    display: flex;
    justify-content: center;
    color: #adacac;
}

.Login-ou span::before {
    content: '';
    border-top: 1px solid #adacac;
    width: 100%;
    margin-top: 10px;
    margin-right: 10px;
}

.Login-ou span::after {
    content: '';
    border-top: 1px solid #adacac;
    width: 100%;
    margin-top: 10px;
    margin-left: 10px
}

.signup-button {
    display: flex;
    margin-right: 10px;
    margin-left: 10px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-flex: 1;
    flex: 1;
    text-align: center;
    background-color: #E83368!important;
    color: #FFFFFF!important;
    margin: 2%!important;
}

.Login-div-row {
    display: flex;
    flex-wrap: wrap;
}

@media screen and (max-width: 600px) {
    .login-card-form-login {
        margin: 0;
        width: 100%;
        height: 100%;
    }
}