.Topo-Relatorios{
  background-color: #27B8D2;
  height: 70px;
}

.Topo-Logo-position{
  padding-top: 17.5px;
  padding-left: 20px;
}

.Topo-Logo-position img{
 width: 110px;
}