body{
    margin: 0;
    padding: 0;
}
::-webkit-scrollbar-track {
    background-color: #F4F4F4;
}
::-webkit-scrollbar {
    width: 8px;
    background: #F4F4F4;
}
::-webkit-scrollbar-thumb {
    background: #b9b8b8;
}
.color-secondary{
    background-color: #f50057 !important;
    color: #ffffff !important;
}
.color-secondary:hover{
    background-color: #c51162 !important;
}
.color-secondaryD{
    background-color: #FFFFFF !important;
    color: #8F8F8F;
}
.color-secondaryD:hover{
    background-color: #fafafa !important;
}
.color-primary{
    background-color: #27b8d2 !important;
    color: #ffffff !important;
}
.color-primary:hover{
    background-color: #24acc4 !important;
}   
.color-primary:disabled {
    background-color: red;
}

.dialog-content button:disabled {
    cursor: auto !important;
    background-color: #b6b6b6 !important;
    color: #ececec !important;
}

.color-disable{
    cursor: auto !important;
    background-color: #b6b6b6 !important;
    color: #ececec !important;
} 

.MuiFormHelperText-root.Mui-error {
    color: #f44336 !important;
    font-weight: 400 !important;
    font-size: 1em !important;
}
.text-dash-graphic{
    font-weight: 600;
    font-size: 2em;
}

.d-flex {
    display: flex;
}

.container {
    margin: 6rem 4rem!important;
}

.containerAlert {
    margin: 1rem 4rem!important;
}

.containerClinic {
    margin: 10rem 4rem!important;
}

.containerClinicAlert {
    margin: 14rem 4rem!important;
}

.card {

    background-color: white;
    min-height: 87vh;
    /* padding: 2rem; */
}

.card_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
}

.container h1, .containerClinic h1, .card h1 {
    color: #8F8F8F;
    font-family: 'Roboto',Arial, Helvetica, sans-serif;
    font-weight: 500;
    margin-bottom: 0.5;
}