.avatar-cadastrar{
    max-width: 100px;
    max-height: 100px;
    border-radius: 50%;
    min-height: 100px;
    min-width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.avatar-cadastrar img{
    border-radius: 50%;
    height: 100%;
    width: 100%;
    object-fit: cover;
   
}
.avatar-container{
    display: flex;
    flex-direction: row;
    align-items: initial;
    padding-left:30px;
    margin-top: 30px;  
}
.text-avatar{
    margin-left: 15px;
    
}
.text-avatar h1{
    color:#8F8F8F;
    font-size: 1rem;
    margin: 0;
}
.text-avatar p{
    color: #8F8F8F;
    font-size: 1rem;
    margin: 0;
}