form .mb-4{
    margin-bottom: 1.5rem;
}
div .titulo-form{
    color: #27b8d2;
    font-size: 1.1em;
    font-weight: 500;
    margin-top: 0;
}
div .custom-title{
    color: #27b8d2;
    font-weight: 500;
    margin-top: 15px;
    margin-bottom: 5px;
}

div .label-title {
    color: rgb(0 0 0 / 55%);
    font-size: 1.3em;
    font-weight: 500;
    margin-top: 0;
}
.div-checkbox-margin{
    margin-left: 2rem;
    margin-bottom: 2rem;
}
.img-input{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #f7f7f7;
    box-shadow: 0px 0px 5px 0px #eaeaea;
}
.img-input img{
    min-width: 100%;
    max-width: 100%;
    height: auto;
}
.envolve-inputImagem, .envolve-inputTabela, .envolve-inputEscala{
    /* margin: 2rem; */
    margin-bottom: 3rem;
    margin-top: 0;
    width: 100%;
}
.MuiSlider-track, .MuiSlider-rail{
    height: 5px !important;
}
.MuiSlider-markLabel.MuiSlider-markLabelActive,.MuiSlider-markLabel{
    margin-top: .25rem !important;
    font-weight: 500 !important;
    font-size: .9em !important;
    color: #4e4e4e !important;
}

.errorBorder{
    border: 1px solid red !important;
    border-radius: 5px;
}

    .image-content-pront{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .image-content-pront
    .MuiInputLabel-outlined {
        width: 96%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .img-input-pront {
        margin-right: 10px;
    }
    .img-input-pront .MuiOutlinedInput-root{
        width: 100%;
    }
    .upload-pront{
        margin-left: 10px;
        width: 30%;
    }
    .img-input-pront{
        display:flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .visualizar-imagem-pront{
        margin-top: 5px;
        display: flex;
        align-items: center;
        font-size: 1.3rem;
        margin-right: 10px;
    }
    .visualizar-imagem-pront small a{
        margin-left: 5px;
        margin-right: 15px;
        color: #076aff;
    }
    .visualizar-imagem-pront .MuiSvgIcon-root{
        width: 1.3rem;
        height: 1.3rem;
        color: #076aff;
    }
    .btn-delete-img{
        padding-left: 0px !important;
        padding-right: 0px !important;
    
    }
    .btn-delete-img .MuiSvgIcon-root{
        color: #fff;
        width: 1.5rem;
        height: 1.5rem;
    }
    .upload-pront-disable{
        padding: 6px 16px;
        font-size: 0.875rem;
        min-width: 64px;
        box-sizing: border-box;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 500;
        line-height: 1.75;
        border-radius: 4px;
        letter-spacing: 0.02857em;
        text-transform: uppercase;
        color: #fff !important;
        color: rgba(0, 0, 0, 0.87);
        box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
        cursor: pointer;
        display: flex;
        justify-content: center;
        margin-left: 10px;
        width: 30%;
        background-color: #cccccc !important;
     }

     .disabledImg{
         background-color: #cccccc !important;
     }
     
     .thumb-container {
        width: 100%;
        display: flex;
        margin-top: 30px;
     }

     .img-thumb {
        display: flex;
        position: relative;
        width: 80px;
        height: 80px;
        overflow: hidden;
        margin: 5px;
        border-radius: 4px;
        box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
        justify-content: center;
        align-items: center;
        background-color: #DDDDDD;
     }

     .img-thumb img {
        width: 100%;
        height: auto;
     }

     .img-thumb a {
        color: #999999;
        font-size: 1rem;
        font-weight: 600;
        text-decoration: none;
     }

     .img-thumb .btn-del {
        position: absolute;
        color: #FFF;
        background-color: #f50057;
        min-width: 0px;
        padding: 3px 3px 0px 3px;
        border-radius: 50px;
        z-index: 999999;
        right: 5px;
        top: 5px;
        /* font-size: 12px; */
        box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
     }
     
     .help-text {
        margin-top: 0.25rem;
        font-size: .875em;
        color: #6c757d;
    }